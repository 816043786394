/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Finance
          </span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to="/statements"
        title="Statements"
        icon="burger-menu"
        fontIcon="bi-person"
      >
        <SidebarMenuItem
          to="/statements/billing"
          title="Billing Statement"
          icon="burger-menu-1"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/statements/customer"
          title="Customer Statement"
          icon="burger-menu-2"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/statements/outstanding"
          title="Outstanding Statement"
          icon="burger-menu-3"
          fontIcon="bi-person"
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/invoicing"
        title="Invoicing"
        fontIcon="bi-sticky"
        icon="bill"
      >
        <SidebarMenuItem
          to="/invoicing/invoices"
          title="Invoices"
          icon="credit-cart"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/invoicing/receipts"
          title="Receipts"
          icon="burger-menu-4"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/invoicing/credit"
          title="Credit Notes"
          icon="two-credit-cart"
          fontIcon="bi-person"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to="/subscriptions/list"
        title="Subscriptions"
        fontIcon="bi-archive"
        icon="dollar"
      />

      <SidebarMenuItem
        to="/subscriptions/bills"
        title="Bills"
        fontIcon="bi-archive"
        icon="finance-calculator"
      />

      <SidebarMenuItem
        to="/orders/order"
        title="Orders"
        icon="office-bag"
        fontIcon="bi-person"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Human Resources
          </span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to="/customer"
        title="Customer Service"
        fontIcon="bi-sticky"
        icon="question"
      >
        <SidebarMenuItem
          to="/customer/service"
          title="Service Ticket"
          icon="user-edit"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/customer/training"
          title="Training Ticket"
          icon="profile-user"
          fontIcon="bi-person"
        />
        <SidebarMenuItem
          to="/customer/trouble"
          title="Trouble Ticket"
          icon="profile-circle"
          fontIcon="bi-person"
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to="/people/contacts"
        title="People"
        icon="people"
        fontIcon="bi-person"
      />

      <SidebarMenuItem
        to="/units/groups"
        title="Unit Groups"
        fontIcon="bi-sticky"
        icon="car-2"
      />

      {/* <SidebarMenuItemWithSub
        to='/units'
        title='Units & Providers'
        fontIcon='bi-sticky'
        icon='car-2'
      >
        <SidebarMenuItem
          to='/units/groups'
          title='Unit Groups'
          icon='car-3'
          fontIcon='bi-person'
        />
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItemWithSub
        to='/subscriptions'
        title='Subscriptions'
        fontIcon='bi-archive'
        icon='dollar'
      >
        <SidebarMenuItem
          to='/subscriptions/list'
          title='List'
          fontIcon='bi-archive'
          icon='element-5'
        />
        <SidebarMenuItem
          to='/subscriptions/bills'
          title='Bills'
          fontIcon='bi-archive'
          icon='finance-calculator'
        />
      </SidebarMenuItemWithSub> */}
    </>
  );
};

export { SidebarMenuMain };
